import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vuetify from './plugins/vuetify';
import router from "./router";
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueAxios, axios)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAAaytVdJsNKTQCX4-VYu7Pa8ujGXk0tt0',
    libraries: 'places',
    region: 'DE',
    language: 'de',
  },
})
