
import { Component, Vue } from "vue-property-decorator";
import Navigation from "@/components/Navigation.vue";
import NavigationElement from "@/types/NavigationElement";

@Component({
  components: {
    Navigation,
  },
})
export default class App extends Vue {
  private navigationElements: NavigationElement[] = [
    {
      label: "home",
      route: "/",
    },
    {
      label: "events",
      route: "/events",
    },
    {
      label: "schlachtfest",
      route: "/schlachtfest",
    },
    {
      label: "sängerheim",
      route: "/saengerheim",
    },
    {
      label: "kontakt",
      target: "_blank",
      href: "mailto:info@mgv1844-flonheim.de",
    },
    {
      label: "impressum",
      route: "/impressum",
    },
  ];
}
