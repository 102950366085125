
import { Component, Prop, Vue } from "vue-property-decorator";
import NavigationElement from "@/types/NavigationElement";

@Component({})
export default class Navigation extends Vue {
  @Prop({
    required: true,
  })
  private title!: string;
  @Prop({
    required: true,
  })
  private elements!: NavigationElement[];

  navigate(route: string | undefined) {
    if (route !== undefined) {
      this.$router.push(route).catch(() => {});
    }
  }
}
