import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "MGV 1844 Flonheim",
            component: () => import(/* webpackChunkName: "Welcome" */ "./views/Hero.vue")
        },
        {
            path: "/impressum",
            name: "Impressum",
            component: () => import(/* webpackChunkName: "Impressum" */ "./views/Impressum.vue")
        },
        {
            path: "/saengerheim",
            name: "Sängerheim",
            component: () => import(/* webpackChunkName: "404" */ "./views/Saengerheim.vue")
        }, 
        {
            path: "/events",
            name: "Events",
            component: () => import(/* webpackChunkName: "404" */ "./views/Events.vue")
        }, 
        {
            path: "/schlachtfest",
            name: "Einladung zum Schlachtfest 2023",
            component: () => import(/* webpackChunkName: "404" */ "./views/Schlachtfest.vue")
        },
        {
            path: "*",
            name: "404",
            component: () => import(/* webpackChunkName: "404" */ "./views/404.vue")
        },
    ],
});

export default router;
